// import {} from 'react-bootstrap';
import {Button,Input,Grid,GridColumn,Image,Form} from 'semantic-ui-react';
import React,{useState} from 'react'
// import axios from 'axios';
import { toast } from "react-toastify";
import Qs from "qs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './Landing.scss';

function Formulario () {
  const [formData, setformData] = useState("");
  // const onChange = e =>{
  //   setformData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
  // }
  const HOST = process.env.HOST || "http://localhost:8080"
  //  const HOST = process.env.HOST || "https://restserver-323001.uw.r.appspot.com"
  
  const onSubmit =(e)=> {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(formData);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(HOST+"/api/webpage/landing-french", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
      e.target.reset();
  //   var str = Qs.stringify(formData)
  //   e.preventDefault();
  //   fetch('https://docs.google.com/forms/u/0/d/e/1FAIpQLSeZGBDsixSCXUzP-3k3Zmh3Ay_FkL7AYOPkNt5534ppZlBnZg/formResponse', {
  //   method: 'post',
  //   mode:'no-cors',
  //   headers: {'Content-Type':'application/x-www-form-urlencoded'},
  //   body: str,
  //   redirect: 'follow'
  // });
  // e.target.reset();
  // toast.success("Your answers were sent");
    };

  return(
 
    <div class="contenedor"> 
    <header className = "App-header-landing">
    <div className = "landing-container">
    <Grid container columns={2}>
    <Grid.Column width={12}>
    <img src = {'https://firebasestorage.googleapis.com/v0/b/landingcomligo.appspot.com/o/ImagenesLandingComligo%2Flogin%20page%20logo.png?alt=media&token=3a2c1909-73d6-4eb3-8943-216c84ae71ba'} className = "logotipo"></img>       
    </Grid.Column>
  </Grid>
    <Grid stackable columns={2}> 
    <Grid.Column  width={2}>
    </Grid.Column>
    <Grid.Column  width={6}>
    <div className="izq">

    <Form onSubmit={onSubmit} >
                  <fieldset>                  
                      <p className="conocer-titulo"><h1>VOUS VOULEZ EN SAVOIR PLUS SUR NOUS</h1></p>
                      <p className="detalles">Veuillez laisser vos coordonnées ici</p>
                      <br></br>
                      <br></br>
                        <input
                          type = "text"                         
                          placeholder = "Prénom*"
                          className="tres"
                          onChange={e => setformData({...formData, firstname: e.target.value})}
                        />
                        <br></br>
                        <br></br>
                        <br></br>
                        <input type = "text" 
                        placeholder = "Nom*" required 
                        className="tres"
                        onChange={e => setformData({...formData, lastname: e.target.value})}
                        />
                        <br></br>
                        <br></br>
                        <br></br>
                        <input type = "text" 
                        placeholder = "Entreprise/Institution"
                        className="tres"
                        onChange={e => setformData({...formData, company: e.target.value})}
                        />
                        <br></br>
                        <br></br>
                        <br></br>
                         <input type = "email" 
                         placeholder = "Adresse électronique*" 
                         required
                         className="tres"
                         onChange={e => setformData({...formData, email: e.target.value})}
                         />
                          <br></br>
                        <br></br>
                        <br></br>
                         <input type = "text" 
                          placeholder = "Téléphone*" required
                          className="tres"
                          onChange={e => setformData({...formData, phone: e.target.value})}
                          />
                         <br></br>
                        <br></br>
                        <br></br>
                         <input type = "text" 
                          placeholder = "Quel cours d'Espagnol vous intéresse?" required
                          className="tres"
                          onChange={e => setformData({...formData, course: e.target.value})}
                          />
  
                  </fieldset>
                  <Button type = "submit" className = "landing-envio">SOUMETTRE</Button>
                </Form>               
     </div>
    </Grid.Column>
    <Grid.Column  width={6} >
     <div className="der">

     <ul className="listado-landing">
                    <li>
                       <p className = "parr-uno">
                       Nous enseignons l'Espagnol, et uniquement l'Espagnol, via une plate-forme unique conçue par des linguistes espagnols natifs.                     </p>                                        
                    </li>
                    <li>
                       <p className = "parr-dos">
                       Notre offre de cours d'Espagnol incomparable va du général à la spécialisation, le tout en suivant les directives du CECR et de l'ACTFL.                 </p>
                    </li>
                    <li>
                       <p className = "parr-tres">                  
Nous prenons un soin particulier à inclure des éléments (inter) culturels dans TOUS nos cours.
                      </p>
                    </li>
                    <li>
                       <p className = "parr-cuatro">
                       Notre faculté fait souvent l’objet d’inspections et suit un plan de formation en permanence sur la systématisation de l'enseignement.                      </p>
                    </li>
                  </ul>        

   
                </div> 
    </Grid.Column>
    <Grid.Column  width={4}>
    </Grid.Column>
  </Grid>
    </div>
  </header>
  </div> 

  );
  
}

export default Formulario;